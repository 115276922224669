import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from '@components/layouts/main.component';
import { LoginComponent } from '@pages/login/login.component';
import { HeaderComponent } from '@components/layouts/header/header.component';
import { FooterComponent } from '@components/layouts/footer/footer.component';
import { SidebarComponent } from '@components/layouts/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@components/layouts/header/messages/messages.component';
import { NotificationsComponent } from '@components/layouts/header/notifications/notifications.component';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@components/layouts/header/user/user.component';
import { ForgotPasswordComponent } from '@pages/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@pages/recover-password/recover-password.component';
import { LanguageComponent } from '@components/layouts/header/language/language.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ControlComponent } from './components/layouts/control/control.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { defineCustomElements } from '@profabric/web-components/loader';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { merchantReducer } from './store/merchant/reducer';
import { orderReducer } from './store/order/reducer';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    MenuItemComponent,
    ControlComponent,
    SidebarSearchComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    StoreModule.forRoot({
      auth: authReducer,
      ui: uiReducer,
      merchant: merchantReducer,
      order: orderReducer
    }),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ProfabricComponentsModule,
    NgxPaginationModule,
    MatDialogModule,
    EffectsModule.forRoot([]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
