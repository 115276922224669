import { createAction, props } from "@ngrx/store";

export const loadMerchant = createAction('[Merchant Page] Load Merchants')

export const loadMerchantSuccess = createAction(
  '[Mearchant API] Mearchant Load Success',
  props<{ merchant: any[] }>()
);

export const loadMerchantFailure = createAction(
  '[Mearchant API] Mearchant Load Failure',
  props<{ error: string }>()
);

