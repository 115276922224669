import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '@components/layouts/main.component';
import { LoginComponent } from '@pages/login/login.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@pages/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@pages/recover-password/recover-password.component';
import { DashboardComponent } from '@modules/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  { path: 'foods', loadChildren: () => import('./modules/foods/foods.module').then(m => m.FoodsModule) },
  { path: 'orders', loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'settings', loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule) },
  { path: 'category', loadChildren: () => import('./modules/category/category.module').then(m => m.CategoryModule) },
  { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'commission', loadChildren: () => import('./modules/commission/commission.module').then(m => m.CommissionModule) },
  { path: 'offers', loadChildren: () => import('./modules/offers/offers.module').then(m => m.OffersModule) },
  { path: 'review', loadChildren: () => import('./modules/review/review.module').then(m => m.ReviewModule) },
  { path: 'report', loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule) },
  { path: 'others', loadChildren: () => import('./modules/others/others.module').then(m => m.OthersModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
