import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public user;
  @Input() merchant: any;

  constructor(private appService: AppService, private authService: AuthService) { }

  ngOnInit(): void {
    // this.user = this.appService.user;
  }

  logout() {
    this.authService.logout();
  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd LLL yyyy');
  }
}
