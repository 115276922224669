<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1">Login</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Sign in to start your session</p>

    <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
      <div class="input-group mb-3">
        <input formControlName="username" class="form-control" placeholder="Username"
          [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-user"></span>
          </div>
        </div>
        <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
          <div *ngIf="f['username'].errors['required']">Username is required</div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input formControlName="password" type="password" class="form-control" placeholder="Password"
          [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors['required']">Password is required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input type="checkbox" id="remember" />
            <label for="remember"> Remember Me </label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-5">
          <pf-button [type]="'submit'" [block]="true" [loading]="isLogging"
            [disabled]="isFacebookLoading || isGoogleLoading">
            Sign In
          </pf-button>
        </div>
        <!-- /.col -->
      </div>
    </form>

    <!-- <div class="social-auth-links text-center mb-3">
            <pf-button
                class="mb-2"
                [block]="true"
                [loading]="isFacebookLoading"
                [disabled]="!isLogging || isGoogleLoading"
                (click)="loginByFacebook()"
            >
                <i class="fab fa-facebook mr-2"></i>
                Sign in using Facebook
            </pf-button>
            <pf-button
                [block]="true"
                [loading]="isGoogleLoading"
                [disabled]="!isLogging || isFacebookLoading"
                theme="danger"
                (click)="loginByGoogle()"
            >
                <i class="fab fa-google mr-2"></i>
                Sign in using Google+
            </pf-button>
        </div> -->

    <p class="mb-1">
      <a [routerLink]="['/forgot-password']">I forgot my password</a>
    </p>
  </div>
  <!-- /.login-card-body -->
</div>
