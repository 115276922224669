import * as Actions from './actions';
import { MerchantAction } from './actions';
import initialState, { MerchantState } from './state';

export function merchantReducer(state: MerchantState = initialState, action: MerchantAction) {
  switch (action.type) {
    case Actions.LOAD_MERCHANT:
      return {
        merchant: state.merchant
      };
    default:
      return state.merchant;
  }
}
