<app-header [merchant]="merchant"></app-header>

<app-sidebar [merchant]="merchant" class="sidebar-dark-primary"></app-sidebar>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<app-control></app-control>
<div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
