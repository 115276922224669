import { AppState } from '@/store/state';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  getMerchant: Subject<any> = new Subject<any>();
  constructor(private store: Store<AppState>) { }

  sendData(merchant: any) {
    this.getMerchant.next(merchant);
  }

}
