<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
  <pf-image src="assets/img/logo.png" alt="AdminLTE Logo" class="brand-image" height="33" width="33" rounded
    style="opacity: 0.8"></pf-image>
  <span class="brand-text font-weight-light">Merchant Panel</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <!-- Sidebar user (optional) -->
  <div class="user-panel mt-3 pb-3 mb-3 d-flex">
    <div class="image">
      <pf-image [src]="merchant.image" fallback-src="assets/img/default-profile.png" class="img-circle" alt="User Image"
        width="34" height="34" rounded></pf-image>
    </div>
    <div class="info">
      <a [routerLink]="['/profile']" class="d-block">
        {{ merchant.title }}
      </a>
    </div>
  </div>

  <div class="form-inline">
    <app-sidebar-search></app-sidebar-search>
  </div>

  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <app-menu-item *ngFor="let item of menus" [menuItem]="item"></app-menu-item>

      <div class="nav-item">
        <a (click)="logout()" href="javascript:void(0);" class="nav-link">
          <i class="nav-icon fas fa-sign-out-alt"></i>
          <p>Sign out</p>
        </a>
      </div>
    </ul>
  </nav>
</div>
