import { Component, OnInit, OnDestroy, Renderer2, HostBinding } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import { CommonService } from '@services/common.service';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { AppState } from '@/store/state';
import { Store } from '@ngrx/store';
import { FetchMerchant } from '@/store/merchant/actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  // public loginForm: UntypedFormGroup;
  loginForm: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  public isGoogleLoading = false;
  public isFacebookLoading = false;

  submitted = false;
  errorMessage: string = '';
  formErrors: any;
  isLogging: boolean = false;

  public merchant: any;

  constructor(
    public authService: AuthService,
    public appService: AppService,
    public service: CommonService,
    public formBuilder: FormBuilder,
    private renderer: Renderer2,
    private toastr: ToastrService,
    public router: Router,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );
    this.loginForm = this.formBuilder.group(
      {
        username: [
          '',
          [
            Validators.required,
          ]
        ],
        password: [
          '',
          [
            Validators.required,
          ]
        ],
      },
    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  async loginByAuth() {
    this.submitted = true;
    this.isLogging = true;
    if (this.loginForm.invalid) {
      this.isLogging = false;
      return;
    }
    (await this.authService.login(this.loginForm.value.username, this.loginForm.value.password)).subscribe({
      next: result => {
        if (result.success) {
          sessionStorage.setItem('loghash', this.service.encrypt("1"));
          sessionStorage.setItem('tokenhash', this.service.encrypt(result['data']['token']));
          sessionStorage.setItem('idhash', this.service.encrypt(result['data']['merchant']['merchant_id']));
          this.onSaveMerchantData(result['data']['merchant']);
          this.isLogging = false;
          this.toastr.success('User logged in successfully!');
          this.router.navigate(['/']);
        } else {
          this.toastr.error('Username or password is incorrect.');
          sessionStorage.setItem('loghash', this.service.encrypt("0"));
          this.errorMessage = 'Username or password is incorrect.';
          this.submitted = false;
          this.isLogging = false;
        }
      },
      error: error => {
        this.submitted = false;
        if (error.status === 422) {
          this.resetFormErrors();
          this.setFormErrors(JSON.parse(error.data.message));
          this.toastr.error(JSON.parse(error.data.message));
          this.isLogging = false;
        } else {
          this.toastr.error('Username or password is incorrect.');
          this.errorMessage = error.data;
          this.isLogging = false;
        }
      }
    })
  }

  setFormErrors(errorFields: any): void {
    for (const key in errorFields) {
      // skip loop if the property is from prototype
      if (!errorFields.hasOwnProperty(key)) {
        continue;
      }

      const message = errorFields[key];
      this.formErrors[key].valid = false;
      this.formErrors[key].message = message;
    }
  }

  resetFormErrors(): void {
    this.formErrors = {
      username: { valid: true, message: '' },
      password: { valid: true, message: '' }
    };
  }

  onSaveMerchantData(merchant: any) {
    this.store.dispatch(new FetchMerchant({ merchant }));
  }

  // async loginByGoogle() {
  //     this.isGoogleLoading = true;
  //     await this.appService.loginByGoogle();
  //     this.isGoogleLoading = false;
  // }

  // async loginByFacebook() {
  //     this.isFacebookLoading = true;
  //     await this.appService.loginByFacebook();
  //     this.isFacebookLoading = false;
  // }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
}
