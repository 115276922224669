import { Action } from '@ngrx/store';


export class FetchOrder implements Action {
  readonly type: string = LOAD_ORDER;
  constructor(public payload: any) { }
}

export type OrderAction =
  | FetchOrder;


import { createAction, props } from '@ngrx/store';

export const LOAD_ORDER = 'LOAD_ORDER Load Order';

export const fetchOrder = createAction(
  LOAD_ORDER,
  props<{ order: any }>()
);
