import { loadMerchant } from '@/store/merchant.actions';
import { selectMerchant } from '@/store/merchant.selector';
import { MerchantState } from '@/store/merchant/state';
import { AppState } from '@/store/state';
import { ToggleSidebarMenu } from '@/store/ui/actions';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { MerchantService } from '@services/merchant.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainLayoutComponent implements OnInit {
  @HostBinding('class') class = 'wrapper';
  public ui: Observable<UiState>;
  public merchant: Observable<MerchantState>;
  public merchantLoaded: boolean = false;

  public merchant$ = this.store.select(selectMerchant);

  sub: Subscription;
  // public merchant: any;

  constructor(
    private renderer: Renderer2,
    private store: Store<AppState>,
    public appService: AppService,
    public merchantService: MerchantService
  ) { }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.merchant = this.store.select('merchant');
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
    this.renderer.addClass(
      document.querySelector('app-root'),
      'layout-fixed'
    );

    this.ui.subscribe(
      ({ menuSidebarCollapsed, controlSidebarCollapsed, darkMode }) => {
        if (menuSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-open'
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-open'
          );
        }

        if (controlSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open'
          );
        } else {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open'
          );
        }

        if (darkMode) {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'dark-mode'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'dark-mode'
          );
        }
      }
    );

    this.store.dispatch(loadMerchant());
    this.getMerchant();
  }

  getMerchant() {
    this.merchantLoaded = false;
    this.merchantService.getMerchant().subscribe(data => {
      this.merchant = data['data'];
      if (this.merchant) {
        this.merchantLoaded = true;
      }
    });
  }

  // ngAfterContentInit() {
  //   this.sub = this.appService.getMerchant.subscribe(
  //     data => {
  //       this.merchant = data;
  //       console.log(this.merchant);
  //     }
  //   )
  // }

  // ngOnDestroy() {
  //   if (this.sub) {
  //     this.sub.unsubscribe()
  //   }
  // }

  onToggleMenuSidebar() {
    this.store.dispatch(new ToggleSidebarMenu());
  }
}
