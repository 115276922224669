import { Action } from '@ngrx/store';

export const LOAD_MERCHANT = 'LOAD_MERCHANT';

export class FetchMerchant implements Action {
  readonly type: string = LOAD_MERCHANT;
  constructor(public payload: any) { }
}

export type MerchantAction =
  | FetchMerchant;
