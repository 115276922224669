import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public menus = MENU;
  @Input() merchant: any;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
  }

  logout() {
    this.authService.logout();
  }
}

export const MENU = [
  {
    name: 'Dashboard',
    iconClasses: 'fas fa-tachometer-alt',
    path: ['/']
  },
  {
    name: 'Orders',
    iconClasses: 'fas fa-database',
    children: [
      {
        name: 'Manage Orders',
        iconClasses: 'far fa-circle',
        path: ['/orders']
      },
      {
        name: 'Cancelled Orders',
        iconClasses: 'far fa-circle',
        path: ['/orders', {'cancelled' : true}]
      }
    ]
  },
  {
    name: 'Categories',
    iconClasses: 'fas fa-puzzle-piece',
    children: [
      {
        name: 'Manage Categories',
        iconClasses: 'far fa-circle',
        path: ['/category']
      },
      {
        name: 'Create Category',
        iconClasses: 'far fa-circle',
        path: ['/category/create']
      }
    ]
  },
  {
    name: 'Food Items',
    iconClasses: 'fa fa-utensils',
    children: [
      {
        name: 'Manage Food Items',
        iconClasses: 'far fa-circle',
        path: ['/foods']
      },
      {
        name: 'Create Food Item',
        iconClasses: 'far fa-circle',
        path: ['/foods/create']
      }
    ]
  },
  {
    name: 'Commission',
    iconClasses: 'fas fa-wallet',
    children: [
      {
        name: 'Statement',
        iconClasses: 'far fa-circle',
        path: ['/commission/statement']
      },
      {
        name: 'Earnings',
        iconClasses: 'far fa-circle',
        path: ['/commission/earning']
      },
      {
        name: 'Withdrawals',
        iconClasses: 'far fa-circle',
        path: ['/commission/withdrawal']
      }
    ]
  },
  {
    name: 'Offers',
    iconClasses: 'fas fa-gift',
    children: [
      {
        name: 'Manage Offers',
        iconClasses: 'far fa-circle',
        path: ['/offers']
      }
    ]
  },
  {
    name: 'Reviews',
    iconClasses: 'fas fa-comments',
    children: [
      {
        name: 'Merchant Reviews',
        iconClasses: 'far fa-circle',
        path: ['/review']
      },
      {
        name: 'Food Reviews',
        iconClasses: 'far fa-circle',
        path: ['/review/foods']
      }
    ]
  },
  {
    name: 'Reports',
    iconClasses: 'fas fa-tasks',
    children: [
      {
        name: 'Orders Report',
        iconClasses: 'far fa-circle',
        path: ['/report/orders']
      },
      {
        name: 'Sales Report',
        iconClasses: 'far fa-circle',
        path: ['/report/sales']
      }
    ]
  },
  {
    name: 'Others',
    iconClasses: 'fas fa-bars',
    children: [
      {
        name: 'Delivery Charge',
        iconClasses: 'far fa-circle',
        path: ['/others/delivery']
      },
      {
        name: 'Minimum Order',
        iconClasses: 'far fa-circle',
        path: ['/others/minimum']
      },
      {
        name: 'Email Logs',
        iconClasses: 'far fa-circle',
        path: ['/others/email-logs']
      },
      {
        name: 'SMS Logs',
        iconClasses: 'far fa-circle',
        path: ['/others/sms-logs']
      }
    ]
  },
  {
    name: 'Settings',
    iconClasses: 'fas fa-cogs',
    children: [
      {
        name: 'Manage Locations',
        iconClasses: 'far fa-circle',
        path: ['/settings']
      }
    ]
  },
  // {
  //   name: 'Logout',
  //   iconClasses: 'fas fa-sign-out-alt',
  //   path: ['/logout']
  // },
];
