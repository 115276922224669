import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  public id: any;
  public token: any;
  private apiURL = environment['API_URL'];


  constructor(private httpClient: HttpClient, private service: CommonService) { }

  getMerchant(): Observable<any> {
    var value = sessionStorage.getItem('idhash')!;
    this.id = this.service.decrypt(value);

    var access_token = sessionStorage.getItem('tokenhash')!;
    this.token = this.service.decrypt(access_token);
    return this.httpClient.get<any>(this.apiURL + 'v1/merchants/' + this.id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      })
    })
      .pipe(
        catchError(this.errorHandler)
      )
  }

  updateMerchant(merchant: any, noteText: any, id: any): Observable<any> {
    var value = sessionStorage.getItem('tokenhash')!;
    this.token = this.service.decrypt(value);
    var data = {
      title: merchant.name,
      phone: merchant.phone,
      restaurant_name: merchant.name,
      restaurant_phone: merchant.phone,
      merchant_type: merchant.type,
      food_category_id: merchant.foodCategoryId,
      contact_name: merchant.contactName,
      contact_phone: merchant.contactPhone,
      contact_email: merchant.contactEmail,
      street: merchant.street,
      city: merchant.city,
      state: merchant.state,
      post_code: merchant.postCode,
      latitude: merchant.latitude,
      longitude: merchant.longitude,
      cousine: merchant.cousine,
      service: merchant.service,
    }
    return this.httpClient.put<any>(this.apiURL + 'v1/merchants/' + id, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      })
    }
    )
      .pipe(
        catchError(this.errorHandler)
      )
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
