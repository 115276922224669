import * as Actions from './actions';
import { OrderAction } from './actions';
import initialState, { OrderState } from './state';

export function orderReducer(state: OrderState = initialState, action: OrderAction) {
  switch (action.type) {
    case Actions.LOAD_ORDER:
      return {
        order: state.order
      };
    default:
      return state.order;
  }
}
