import { Injectable, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  public encrypt(string: String) {
    return CryptoJS.AES.encrypt(string.toString(), 'bizdire').toString();
  }

  public decrypt(string: String) {
    var bytes = CryptoJS.AES.decrypt(string.toString(), 'bizdire');
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
