<pf-dropdown hide-arrow class="user-menu">
  <div slot="button">
    <a class="nav-link">
      <pf-image [src]="merchant.image" fallback-src="assets/img/default-profile.png" class="user-image-small" width="25" height="25"
        alt="User Image" rounded></pf-image>
    </a>
  </div>
  <div slot="menu">
    <li class="user-header bg-primary">
      <pf-image [src]="merchant.image" fallback-src="assets/img/default-profile.png" class="user-image-big" alt="User Image" width="90"
        height="90" rounded></pf-image>

      <p>
        <span>{{merchant.contact_email}}</span>
        <small>
          <span class="mr-2">Member since</span>
          <span>{{merchant.created_at | date}}</span>
        </small>
      </p>
    </li>
    <!-- Menu Body -->
    <!-- <li class="user-body">
      <div class="row">
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Followers</a>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Sales</a>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Friends</a>
        </div>
      </div>
    </li> -->
    <!-- Menu Footer-->
    <li class="user-footer">
      <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
        Profile
      </a>
      <a (click)="logout()" class="btn btn-default btn-flat float-right">
        Sign out
      </a>
    </li>
  </div>
</pf-dropdown>
